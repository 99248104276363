@import "mixins";

@font-face {
  font-family: "ARS";
  font-weight: normal;
  src: url(../fonts/arsmaquettepro-regular-webfont.woff) format("woff");
}
@font-face {
  font-family: "ARS";
  font-weight: 600;
  src: url(../fonts/arsmaquettepro-medium-webfont.woff) format("woff");
}

body {
  font-family: "ARS", sans-serif;
}

.eyebrow {
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  letter-spacing: 5.7px;
  text-align: center;
  text-transform: uppercase;
}

h1 {
  font-weight: normal;
  font-size: 53px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 45px;
  }
  @media (max-width: 550px) {
    font-size: 36px;
  }
}

h2 {
  font-weight: 600;
  font-size: 26px;
  color: #ebede3;
  letter-spacing: 0;
  text-align: center;
  line-height: (28/26);

  @media (max-width: 767px) {
    font-size: 24px;
  }

  &.bigger {
    font-variant: normal;
    font-size: 40px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 36px;
    }
    @media (max-width: 550px) {
      font-size: 30px;
    }
  }
}

p {
  font-weight: 600;
  font-size: 18px;
  color: #ebede3;
  letter-spacing: 0;
  text-align: center;
  line-height: 22px;

  @media (max-width: 550px) {
    font-size: 16px;
  }
}

.button {
  @include remove-appearance;
  font-family: "ARS", sans-serif;
  height: 46px;
  padding: 0 25px;
  padding-right: 20px;
  border-radius: 8px;
  background: #ebede3;
  transition: all 0.2s;
  margin-top: 50px;

  font-weight: 600;
  font-size: 16px;
  color: #df2b10;
  letter-spacing: 0;
  line-height: 46px;

  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1),
    inset 0 3px 3px 0 rgba(255, 255, 255, 0.2);
  cursor: pointer;

  &:after {
    content: url(../assets/chevron-right.svg);
    display: inline-block;
    margin-left: 10px;
    transform: translateY(3px);
  }

  &:hover {
    background: #cac5b8;
  }

  &--big {
    font-size: 28px;
    height: 60px;
    line-height: 60px;

    &:after {
      content: url(../assets/chevron-right-big.svg);
      display: inline-block;
      margin-left: 10px;
      transform: translateY(3px);
    }
  }
}
