@mixin variant($selector) {
  @at-root #{$selector} #{&} {
    @content;
  }
}

@mixin remove-appearance() {
  appearance: none;
  -webkit-appearance: none;
  border: 0;
  background: none;
  padding: 0;
}
