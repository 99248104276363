body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: Lato, sans-serif;

  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
}

.content-holder {
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;

  text-align: center;

  @media (max-width: 710px) {
    width: auto;
    margin-left: 15px;
    margin-right: 15px;
  }
}

#root {
  overflow-x: hidden;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.section {
  margin-bottom: 20px;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
  &:first-child {
    margin-top: 100px;
    @media (max-height: 950px) {
      margin-top: 50px;
    }
  }
}

.full-screen-card {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  //background: green;
}
