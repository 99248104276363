#root {
  .dd-wrapper {
    width: 250px;
    color: #ebede3;
    font-size: 16px;
    line-height: 35px;
    margin-left: auto;
    margin-right: auto;
  }
  .dd-header {
    background: #544d44;
    border-radius: 8px;
    border: none;
    box-shadow: inset 0 3px 3px 1px rgba(0, 0, 0, 0.15);

    svg path {
      fill: #ebede3;
    }
  }
  .dd-list {
    background: #544d44;
    max-height: none;
    border: 0;
    border-radius: 0 0 8px 8px;
    box-shadow: none;
    transform: translateY(-8px);
  }
  .dd-scroll-list {
    max-height: none;
    overflow: auto;
  }
  .dd-list-item {
    font-size: 16px;
    line-height: 35px;
    padding: 0 12px;
    position: relative;
    display: block;

    svg {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
    }

    svg path {
      fill: #ebede3;
    }

    &:hover {
      background-color: #6f665b;
    }
    &:after {
      display: block;
      content: "";
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: #6f665b;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}
