.c-result {
  &__wrap {
    position: relative;
    display: block;
  }
  &__img {
    position: relative;
    .imgshadow {
      visibility: hidden;
      img {
        position: relative;
      }
    }
    img {
      height: auto;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
  }
  &__risingsun {
    background: #e84a32;
    width: 100%;
    height: 100vh;
    display: block;
    //position: absolute;
    //top: 500px;
    left: 0;
    z-index: 0;
    &:before {
      content: "";
      width: 100%;
      display: block;
      height: 100px;
      //top: 99px;
      top: 0;
      transform: translateY(-99px);
      background: url(../../assets/risingsun.png) center center no-repeat;
      background-size: 100% 100px;
    }
  }
  &__ribbon {
    height: 102px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: inline-block;
    position: absolute;
    bottom: 10px;
    left: 50%;
    max-width: 90vw !important;
    transform: translateX(-50%);

    padding-left: 102px;
    padding-right: 102px;

    > div {
      display: inline-block;
    }

    .left {
      position: absolute;
      left: 0;
      top: 0;
      width: 102px;
      height: 102px;
      background: url(../../assets/ribbon-left.png) no-repeat;
      background-size: contain;
    }
    .mid {
      height: 102px;
      background: url(../../assets/ribbon-mid.png) repeat-x;
      background-size: 100% 102px;
      position: relative;
      //background-position: 0px 1px;
      width: 100%;
      .label {
        font-weight: 600;
        font-size: 16px;
        color: #544d44;
        letter-spacing: 0.85px;
        line-height: 19px;
        text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.5);
        text-align: center;
        transform: translateY(13px);
        height: 50px;
        transition: all 0.1s;

        &:hover {
          color: black;
        }

        svg {
          margin-left: 10px;
        }

        span {
          display: flex;
          height: 100%;
          width: 100%;
          align-items: center;
          justify-content: center;
          background: #ebede4;
          position: relative;
          &:before,
          &:after {
            width: 2px;
            height: 50px;
            background: #ebede4;
            //background: red;
            position: absolute;
            display: block;
            content: "";
            top: 0px;
          }
          &:before {
            left: -1px;
          }
          &:after {
            right: -1px;
          }
        }
      }
    }
    .right {
      position: absolute;
      right: 0;
      top: 0;
      width: 102px;
      height: 102px;
      background: url(../../assets/ribbon-right.png) no-repeat;
      background-size: contain;
    }

    @media (max-width: 550px) {
      padding-left: 70px;
      padding-right: 70px;
      //width: 220px !important;
      .left,
      .right {
        width: 70px;
        //height: 110px;
        background-size: 100% 100%;
      }
      .mid {
        //height: 110px;
        background-size: 100% 100%;
        .label {
          font-size: 15px;
          line-height: 15px;
        }
      }
    }

    @media (max-width: 340px) {
      .mid {
        //height: 110px;
        background-size: 100% 100%;
        .label {
          font-size: 13px;
          line-height: 13px;
        }
      }
    }
  }

  &__again {
    color: white;
    text-decoration: none;
    margin-top: 50px;
    display: block;
    max-width: 450px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;

    div + div {
      margin-top: 20px;
    }
    .button {
      max-width: 250px;
      margin-left: auto !important;
      margin-right: auto !important;
      display: block;
    }
  }
}
