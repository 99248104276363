body {
  background: #f8312b url(./assets/bg-tile.png) center center repeat;
  color: white;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100vh;
}

.app-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: linear-gradient(
    270deg,
    #ce321b 0%,
    rgba(232, 74, 50, 0.09) 35%,
    rgba(232, 74, 50, 0) 50%,
    rgba(232, 74, 50, 0.09) 64%,
    #ce321b 100%
  );
}
